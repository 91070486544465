<template>
  <!--SHOPPING CART-->
  <b-modal
    id="side-cart"
    size="xl"
    centered
    :hide-footer="shoppingBag.length == 0"
    header-border-variant="white"
    footer-border-variant="white"
    header-class="flex-column pb-0"
    footer-class="justify-content-between"
    body-class="py-0 cart-item-scroll"
    @shown="firebaseViewCart(shoppingBag); resizeSideCart()"
  >
    <!-- Shopping Bag Header -->
    <template #modal-header>
      <div class="d-flex align-items-center w-100 mb-1">
        <fawesome-pro variant="far" icon="chevron-left" class="side-cart__back-icon cursor-pointer" @click.native="$bvModal.hide('side-cart')"/>
        <div class="side-cart__title ml-lg-3 ml-2 flex-grow-1">Shopping Bag</div>
        <small class="align-self-start pr-1">{{ `Showing ${shoppingBag.length} item${shoppingBag.length == 1 ? "" : "s"}` }}</small>
      </div>
      <UrlHandler v-if="announcementBanner" :url="announcementBanner.url" class="p-0 w-100 mb-1">
        <b-img-lazy :src="announcementBanner.image_url" :title="announcementBanner.name" :alt="announcementBanner.name" class="d-none d-lg-block" fluid />
        <b-img-lazy :src="announcementBanner.image_mobile_url" :title="announcementBanner.name" :alt="announcementBanner.name" class="d-lg-none" fluid-grow />
      </UrlHandler>
      <!-- <div class="store-closed-box store-closed-box--side-cart" v-if="shoppingBag.findIndex(el => el.is_closed == true && el.selected == true) > -1">
        <fawesome-pro variant="far" icon="exclamation-triangle" size="lg" class="store-closed-notes-info px-2" />
        <div class="ml-2">
          <b>Toko sedang tutup</b>
          <div class="mt-1">Hey, maaf banget, salah satu toko dari barang yang kamu pilih lagi tutup nih. Tapi tenang aja, kamu masih bisa berbelanja kok!</div>
        </div>
      </div> -->
      <div class="side-cart side-cart--header side-cart--hide-mobile" v-if="shoppingBag.length > 0">
        <div class="styled-checkbox"  style="margin-top: -5px">
          <label class="container-checkbox">
            <input
              class="d-none"
              type="checkbox"
              v-model="selectAll"
              @click="toggleSelectAll"
            />
            <span class="checkmark rounded-0"></span>
          </label>
        </div>
        <div>Item</div>
        <div>Quantity</div>
        <div>Price</div>
      </div>
      <hr class="hr-line-checkout mb-0 d-none d-lg-block">
    </template>
    <!-- Shopping Bag Content -->
    <div class="row" v-if="shoppingBag.length == 0">
      <div class="col-md-12 text-center mt-5">
        <img src="@/assets/img/shoping-bag-empty.png" alt="" />
        <h4>Oops, your shopping bag is empty</h4>
      </div>
    </div>
    <template v-else>
      <div class="shadow mb-3 pb-2 rounded" v-for="(seller, i) in sellers" :key="i">
        <div class="store-closed-box store-closed-box--side-cart" v-if="seller.is_closed">
          <fawesome-pro variant="far" icon="exclamation-triangle" size="lg" class="store-closed-notes-info px-2" />
          <div class="ml-2">
            <b>Toko sedang tutup</b>
            <div class="mt-1">Hey, maaf banget, salah satu toko dari barang yang kamu pilih lagi tutup nih. Tapi tenang aja, kamu masih bisa berbelanja, dan barang kamu akan diproses pada tanggal <b>{{ formatEndDateClose(seller.end_date_close) }}</b></div>
          </div>
        </div>
        <div class="side-cart side-cart--content p-1">
          <div class="styled-checkbox">
            <label class="container-checkbox">
              <input
                type="checkbox"
                v-model="seller.selected"
                @click="toggleSelectMerchant($event, i)"
              />
              <span
                class="checkmark rounded-0"
              ></span>
            </label>
          </div>
          <div class="pt-1">{{ seller.seller_name }}</div>
        </div>
        <hr class="my-0" />
        <div class="side-cart side-cart--content p-1" :class="{ 'mb-1': index < seller.products.length - 1 }" v-for="(chart, index) in seller.products" :key="index">
          <!-- Side cart item checkbox -->
          <div class="styled-checkbox">
            <label class="container-checkbox">
              <input
                type="checkbox"
                v-model="chart.selected"
                @click="toggleSelectItem($event, i, index)"
                :disabled="disabledCondition(chart)"
              />
              <span
                class="checkmark rounded-0"
                :class="{ 'product-disabled' : disabledCondition(chart) }"
              ></span>
            </label>
          </div>
          <!-- Side cart product info -->
          <div
            class="d-flex"
            :class="{ 'product-disabled' : disabledCondition(chart) }"
          >
            <img 
              :src="chart.product_detail.product_images[0].medium_image_url"
              :alt="setProductImgAltText(chart.product_detail)"
              class="side-cart__product-img"
              :class="{'p-2' : chart.product_detail.product_images[0].medium_image_url.indexOf('default-image') >= 0}"
              @error="replaceByDefaultImage(chart.product_detail.product_images[0])"
            />
            <div class="side-cart__product-desc">
              <router-link
                :to="`/product/${$helpers.format.slug(`${chart.product_detail.name} ${chart.product_detail.id}`)}`"
                class="side-cart__product-desc--brand"
                @click.native="$bvModal.hide('side-cart')"
                >{{ chart.product_detail.brand_name }}</router-link
              >
              <div>
                <router-link
                  :to="`/product/${$helpers.format.slug(`${chart.product_detail.name} ${chart.product_detail.id}`)}`"
                  @click.native="$bvModal.hide('side-cart')"
                >
                  {{ chart.product_detail.name }}
                </router-link
                ><br />
                <template
                  v-if="
                    chart.product_variance_attribute.color.name.charAt(
                      0
                    ) != '-'
                  "
                  >Color:
                  {{ chart.product_variance_attribute.color.name }}
                </template>
                <template
                  v-if="
                    chart.product_variance_attribute.color.name.charAt(
                      0
                    ) != '-' &&
                    chart.product_variance_attribute.size.international.charAt(
                      0
                    ) != '-'
                  "
                  >|
                </template>
                <template
                  v-else-if="
                    chart.product_variance_attribute.color.name.charAt(
                      0
                    ) == '-' &&
                    chart.product_variance_attribute.size.international.charAt(
                      0
                    ) == '-'
                  "
                >
                </template>
                <template
                  v-if="
                    chart.product_variance_attribute.size.international.charAt(
                      0
                    ) != '-'
                  "
                  >Size:
                  {{
                    chart.product_variance_attribute.size
                      .international
                  }}
                </template>
                <template v-if="chart.estimated_time_ready">
                  <div>Estimated Delivery:</div>
                  <b>{{ formatEpochMsToLocalTime(chart.estimated_time_ready) }}</b>
                </template>
              </div>
              <div
                class="product-status-badge side-cart--hide-mobile"
                :class="setProductBadgeColor(chart)"
              >
                {{ setProductBadgeText(chart) }}
              </div>
              <div v-if="chart.product_variance_attribute.total_stock < 3 || chart.quantity > chart.product_variance_attribute.total_stock" class="text-danger side-cart--hide-mobile d-md-inline">
                Sisa {{ chart.product_variance_attribute.total_stock }}
              </div>
              <!-- Product price & Input product quantity for mobile view -->
              <div
                class="side-cart__product-price d-lg-none d-inline-block product-price"
                :class="{ 
                  'product-disabled' : disabledCondition(chart),
                  'sale-price': (chart.product_detail.product_type == 4 && chart.product_detail.discount_active_bundle) || chart.product_detail.discount_active || chart.product_detail.bundle_dynamic_price
                }"
              >
                <span>
                  {{ formatPrice(chart.product_detail.price_display) }}
                  <span
                    class="old-price text-lg-center text-left"
                    v-if="(chart.product_detail.product_type == 4 && chart.product_detail.discount_active_bundle) || chart.product_detail.discount_active || chart.product_detail.bundle_dynamic_price"
                  >
                    {{ formatPrice(chart.product_detail.price) }}
                  </span>
                </span>
              </div>
              <div
                class="side-cart__product-quantity product-quantity d-lg-none d-flex"
                :class="{ 'product-disabled' : chart.bundled_product.length || disabledCondition(chart)  }"
              >
                <button
                  :disabled="chart.bundled_product.length || disabledCondition(chart) "
                  type="button"
                  class="btn-qty-minus"
                  min="1"
                  @click="reduceQuantity(chart, i)"
                >
                  <img src="@/assets/img/ic-minus.svg" alt="" />
                </button>
                <input
                  readonly
                  type="number"
                  class="form-control input-number"
                  :value="chart.quantity"
                  min="1"
                  :max="chart.product_detail.total_stock"
                />
                <button
                  :disabled="chart.bundled_product.length || disabledCondition(chart) "
                  type="button"
                  class="btn-qty-plus"
                  @click="addQuantity(chart, i)"
                >
                  <img src="@/assets/img/ic-plus.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
          <!-- Side cart product quantity -->
          <div class="side-cart__product-quantity d-none d-lg-flex flex-nowrap justify-content-center" :class="{ 'product-disabled' : chart.bundled_product.length || disabledCondition(chart)  }">
              <button
              :disabled="chart.bundled_product.length || disabledCondition(chart) "
              type="button"
              class="btn-qty-minus"
              @click="reduceQuantity(chart, i)"
            >
              <img src="@/assets/img/ic-minus.svg" alt="" />
            </button>
            <input
              type="number"
              class="form-control input-number"
              :value="chart.quantity"
              min="1"
              :max="chart.product_detail.total_stock"
              readonly
            />
            <button
              :disabled="chart.bundled_product.length || disabledCondition(chart) "
              type="button"
              class="btn-qty-plus"
              @click="addQuantity(chart, i)"
            >
              <img src="@/assets/img/ic-plus.svg" alt="" />
            </button>
          </div>
          <!-- Side cart product price -->
          <div
            class="side-cart__product-price side-cart--hide-mobile product-price"
            :class="{
              'product-disabled' : disabledCondition(chart),
              'sale-price': (chart.product_detail.product_type == 4 && chart.product_detail.discount_active_bundle) || chart.product_detail.discount_active || chart.product_detail.bundle_dynamic_price
            }"
          >
            <span>
              {{ formatPrice(chart.product_detail.price_display) }}
              <span
                class="old-price text-lg-center text-left"
                v-if="(chart.product_detail.product_type == 4 && chart.product_detail.discount_active_bundle) || chart.product_detail.discount_active || chart.product_detail.bundle_dynamic_price"
              >
                {{ formatPrice(chart.product_detail.price) }}
              </span>
            </span>
          </div>
          <!-- Side cart product remove from cart -->
          <div class="side-cart__product-remove side-cart--hide-mobile">
            <img class="cursor-pointer" width="15" src="@/assets/img/delete-icon.svg" alt="" @click="removeItem(chart)" />
          </div>
          <!-- Side cart additional element for mobile view -->
          <div class="side-cart__additional side-cart--hide-desktop">
            <div
              class="product-status-badge"
              :class="setProductBadgeColor(chart)"
            >
              {{ setProductBadgeText(chart) }}
            </div>
            <div v-if="chart.product_variance_attribute.total_stock < 3 || chart.quantity > chart.product_variance_attribute.total_stock" class="text-danger">
              Sisa {{ chart.product_variance_attribute.total_stock }}
            </div>
            <img class="cursor-pointer pb-2" width="15" src="@/assets/img/delete-icon.svg" alt="" @click="removeItem(chart)" />
          </div>
          <div class="side-cart__product-bundle" v-if="chart.bundled_product && chart.bundled_product.length">
            <Accordion title="Bundle Product Detail" class="mt-1">
              <ProductRow :products="chart.bundled_product" class="d-flex" />
            </Accordion>
          </div>
        </div>
      </div>
      <div class="shadow mb-3 pb-2 rounded" v-if="unavailable.length">
        <div class="p-2">Tidak bisa diproses</div>
        <hr class="my-0">
        <div class="side-cart side-cart--content p-1" v-for="(chart, index) in unavailable.slice(0, !showAll ? 3 : unavailable.length)" :key="index">
          <!-- Side cart item checkbox -->
          <div class="styled-checkbox">
            <label class="container-checkbox">
              <input
                type="checkbox"
                v-model="chart.selected"
                disabled
              />
              <span
                class="checkmark rounded-0 product-disabled"
              ></span>
            </label>
          </div>
          <!-- Side cart product info -->
          <div
            class="d-flex product-disabled"
          >
            <img 
              :src="chart.product_detail.product_images[0].medium_image_url"
              :alt="setProductImgAltText(chart.product_detail)"
              class="side-cart__product-img"
              :class="{'p-2' : chart.product_detail.product_images[0].medium_image_url.indexOf('default-image') >= 0}"
              @error="replaceByDefaultImage(chart.product_detail.product_images[0])"
            />
            <div class="side-cart__product-desc">
              <router-link
                :to="`/product/${$helpers.format.slug(`${chart.product_detail.name} ${chart.product_detail.id}`)}`"
                class="side-cart__product-desc--brand"
                @click.native="$bvModal.hide('side-cart')"
                >{{ chart.product_detail.brand_name }}</router-link
              >
              <div>
                <router-link
                  :to="`/product/${$helpers.format.slug(`${chart.product_detail.name} ${chart.product_detail.id}`)}`"
                  @click.native="$bvModal.hide('side-cart')"
                >
                  {{ chart.product_detail.name }}
                </router-link
                ><br />
                <template
                  v-if="
                    chart.product_variance_attribute.color.name.charAt(
                      0
                    ) != '-'
                  "
                  >Color:
                  {{ chart.product_variance_attribute.color.name }}
                </template>
                <template
                  v-if="
                    chart.product_variance_attribute.color.name.charAt(
                      0
                    ) != '-' &&
                    chart.product_variance_attribute.size.international.charAt(
                      0
                    ) != '-'
                  "
                  >|
                </template>
                <template
                  v-else-if="
                    chart.product_variance_attribute.color.name.charAt(
                      0
                    ) == '-' &&
                    chart.product_variance_attribute.size.international.charAt(
                      0
                    ) == '-'
                  "
                >
                </template>
                <template
                  v-if="
                    chart.product_variance_attribute.size.international.charAt(
                      0
                    ) != '-'
                  "
                  >Size:
                  {{
                    chart.product_variance_attribute.size
                      .international
                  }}
                </template>
                <template v-if="chart.estimated_time_ready">
                  <div>Estimated Delivery:</div>
                  <b>{{ formatEpochMsToLocalTime(chart.estimated_time_ready) }}</b>
                </template>
              </div>
              <div
                class="product-status-badge side-cart--hide-mobile"
                :class="setProductBadgeColor(chart)"
              >
                {{ setProductBadgeText(chart) }}
              </div>
              <!-- Product price & Input product quantity for mobile view -->
              <div
                class="side-cart__product-price d-lg-none d-inline-block product-price"
                :class="{
                  'product-disabled' : disabledCondition(chart),
                  'sale-price': (chart.product_detail.product_type == 4 && chart.product_detail.discount_active_bundle) || chart.product_detail.discount_active || chart.product_detail.bundle_dynamic_price
                }"
              >
                <span>
                  {{ formatPrice(chart.product_detail.price_display) }}
                  <span
                    class="old-price text-lg-center text-left"
                    v-if="(chart.product_detail.product_type == 4 && chart.product_detail.discount_active_bundle) || chart.product_detail.discount_active || chart.product_detail.bundle_dynamic_price"
                  >
                    {{ formatPrice(chart.product_detail.price) }}
                  </span>
                </span>
              </div>
              <div
                class="side-cart__product-quantity product-quantity d-lg-none d-flex product-disabled"
              >
                <button
                disabled
                  type="button"
                  class="btn-qty-minus"
                  min="1"
                >
                  <img src="@/assets/img/ic-minus.svg" alt="" />
                </button>
                <input
                  readonly
                  type="number"
                  class="form-control input-number"
                  :value="chart.quantity"
                  min="1"
                  :max="chart.product_detail.total_stock"
                />
                <button
                  disabled
                  type="button"
                  class="btn-qty-plus"
                >
                  <img src="@/assets/img/ic-plus.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
          <!-- Side cart product quantity -->
          <div class="side-cart__product-quantity d-none d-lg-flex flex-nowrap justify-content-center product-disabled">
            <button
              disabled
              type="button"
              class="btn-qty-minus"
            >
              <img src="@/assets/img/ic-minus.svg" alt="" />
            </button>
            <input
              type="number"
              class="form-control input-number"
              :value="chart.quantity"
              min="1"
              :max="chart.product_detail.total_stock"
              readonly
            />
            <button
              disabled
              type="button"
              class="btn-qty-plus"
            >
              <img src="@/assets/img/ic-plus.svg" alt="" />
            </button>
          </div>
          <!-- Side cart product price -->
          <div class="side-cart__product-price side-cart--hide-mobile product-price product-disabled">
            <span>
              {{ formatPrice(chart.product_detail.price_display) }}
              <span
                class="old-price text-lg-center text-left"
                v-if="(chart.product_detail.product_type == 4 && chart.product_detail.discount_active_bundle) || chart.product_detail.discount_active || chart.product_detail.bundle_dynamic_price"
                >{{ formatPrice(chart.product_detail.price) }}</span
              >
            </span>
          </div>
          <!-- Side cart product remove from cart -->
          <div class="side-cart__product-remove side-cart--hide-mobile">
            <img class="cursor-pointer" width="15" src="@/assets/img/delete-icon.svg" alt="" @click="removeItem(chart)" />
          </div>
          <!-- Side cart additional element for mobile view -->
          <div class="side-cart__additional side-cart--hide-desktop">
            <div
              class="product-status-badge"
              :class="setProductBadgeColor(chart)"
            >
              {{ setProductBadgeText(chart) }}
            </div>

            <img class="cursor-pointer pb-2" width="15" src="@/assets/img/delete-icon.svg" alt="" @click="removeItem(chart)" />
          </div>
          <div class="side-cart__product-bundle" v-if="chart.bundled_product && chart.bundled_product.length">
            <Accordion title="Bundle Product Detail">
              <ProductRow :products="chart.bundled_product" class="d-flex" />
            </Accordion>
          </div>
        </div>
      </div>
      <button v-if="unavailable.length > 3" class="btn btn-block border-gold shadow mb-3 pb-2 rounded" style="font-weight: normal;" @click="showAll = !showAll">
        Show {{ !showAll ? 'All' : 'Less' }}
      </button>
    </template>
    
    <!-- Shopping Bag Footer -->
    <template #modal-footer v-if="shoppingBag.length > 0">
      <hr class="hr-line-checkout mt-0">
      <!-- Desktop View -->
      <label class="w-100 d-none d-lg-block">Subtotal
        <b class="float-right text-gold">{{ formatPrice(totalCart()) }}</b>
      </label>

      <!-- Mobile View -->
      <div class="d-flex d-lg-none justify-content-between w-55">
        <div class="styled-checkbox">
          <label class="container-checkbox">
            Select All
            <input
              class="d-none"
              type="checkbox"
              v-model="checkedState"
              :value="!checkedState"
              @click="toggleSelectAll"
            />
            <span class="checkmark rounded-0"></span>
          </label>
        </div>
        <div class="text-right font-12px">
          <div>Sub Total</div>
          <b class="text-gold">{{ formatPrice(totalCart()) }}</b>
        </div>
      </div>
      <button type="button" class="btn btn-wz-proceed btn-block side-cart__checkout-btn" :disabled="totalCart() == 0" @click="checkout()">CHECKOUT</button>
    </template>
    <AdjustQuantity :items="invalidProducts" @adjustQuantity="adjustQuantity(invalidProducts)" />
  </b-modal>
</template>

<script>
import { toaster, userHelper, formatter } from "@/_helpers";
const UrlHandler = () => import('@/components/mains/UrlHandler');
const Accordion = () => import('@/components/bundling/Accordion');
const ProductRow = () => import('@/components/bundling/ProductRow');
import AdjustQuantity from '@/components/shoppingBag/AdjustQuantity.vue';
import axios from "axios";
import moment from "moment";

export default {
  name: "SideCart",
  components: {
    UrlHandler,
    Accordion,
    ProductRow,
    AdjustQuantity,
  },
  data() {
    return {
      token: this.$store.getters.getUserToken,
      checkedState: true,
      announcementBanner: null,
      showAll: false,
      selectAll: true,
      invalidProducts: [],
      sellers: [],
      unavailable: [],
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    shoppingBag() {
      return this.$store.getters.getShoppingBag;
    }
  },
  mounted() {
    if (this.shoppingBag.length == 0) this.getCart();
    else this.bagMapping()

    // this.firebaseViewCart(this.shoppingBag);
    this.getMiniBanner('bag')

    window.addEventListener('resize', () => {
      this.resizeSideCart();
    });
  },
  watch: {
    shoppingBag() {
      this.bagMapping()
    }
  },
  methods: {
    itemMapper(bag) {
      return {
        id: bag.id,
        bundled_product: bag.bundled_product ?? [],
        product_detail: (res => {res.order_notes = ''; delete res.seller; return res})(JSON.parse(JSON.stringify(bag.product))),
        product_detail_variance_id: bag.product_detail_variance_id,
        product_variance_attribute: bag.product_variance_attribute,
        quantity: bag.quantity,
        status: bag.status,
        status_stock: bag.status_stock,
        variant_has_stock: bag.variant_has_stock,
        checkout_validity: bag.checkout_validity,
        selected: bag.checkout_validity,
        estimated_time_ready: bag.estimated_time_ready,
        is_closed: bag.is_closed
      }
    },
    bagMapping() {
      // console.log(this.shoppingBag);
      this.sellers = []
      this.unavailable = []
      for (const bag of this.shoppingBag) {
        const processable = bag.variant_has_stock && !bag.product_variance_attribute.is_deleted && bag.product.is_active && !bag.product.is_deleted && bag.checkout_validity;
        const found = this.sellers.find(el => el.id == bag.product.seller.id)
        const product = this.itemMapper(bag)
        if (!processable) this.unavailable.push(product)
        else if (found) {
          if (!product.selected) this.selectAll = found.selected = false
          found.products.push(product)
        } else {
          const { seller } = bag.product
          seller.seller_name = bag.product.seller_name
          seller.products = [product]
          seller.selected = seller.products.length == seller.products.filter(el => el.selected).length
          delete seller.name
          this.sellers.push(seller)
        }
      }
      this.invalidProducts = JSON.parse(JSON.stringify(this.sellers)).map(el => {
        el.products = []
        return el
      })
    },
    toggleSelectAll(event) {
      const globalInvalidProducts = []
      for (const i in this.sellers) {
        for (const el of this.sellers[i].products) {
          if (!el.checkout_validity) {
            this.invalidProducts[i].products.push(el)
            globalInvalidProducts.push(el)
          }
        }
      }
      if (globalInvalidProducts.length) {
        this.$bvModal.show('modal-adjust-quantity')
        event.target.checked = false
      } else 
        for (const i in this.sellers)
          this.toggleSelectMerchant(event, i)
    },
    toggleSelectMerchant(event, merchantIndex) {
      const seller = this.sellers[merchantIndex]
      const { products } = seller
      const { checked } = event.target
      this.invalidProducts[merchantIndex].products = products.filter(el => !el.checkout_validity)
      if (this.invalidProducts[merchantIndex].products.length) {
        this.$bvModal.show('modal-adjust-quantity')
        event.target.checked = !checked
      } else {
        products.map(el => {el.selected = checked; return el})
        seller.selected = checked
        this.sellers.splice(merchantIndex, 1, seller)
      }
      
      this.selectAll = this.sellers.length == this.sellers.filter(el => el.selected).length
    },
    toggleSelectItem(event, merchantIndex, itemIndex) {
      const seller = this.sellers[merchantIndex]
      const product = seller.products[itemIndex]
      const { checked } = event.target
      
      if (!product.checkout_validity) {
        this.invalidProducts[merchantIndex].products = [product]
        this.$bvModal.show('modal-adjust-quantity')
        event.target.checked = !checked
      } else product.selected = checked
      seller.selected = seller.products.length == seller.products.filter(el => el.selected).length
      this.selectAll = this.sellers.length == this.sellers.filter(el => el.selected).length
      this.sellers.splice(merchantIndex, 1, seller)
    },
    adjustQuantity(sellers) {
      this.$bvModal.hide('modal-adjust-quantity')
      sellers.forEach((seller, i) => {
        seller.products.forEach((product) => {
          product.selected = product.checkout_validity = true
          const realIndex = this.sellers[i].products.findIndex(el => el.id == product.id)
          this.sellers[i].products[realIndex] = product
          this.reduceQuantity(this.sellers[i].products[realIndex], i)
        })
        this.sellers[i].selected = this.sellers[i].products.length == this.sellers[i].products.filter(el => el.selected).length
        this.selectAll = this.sellers.length == this.sellers.filter(el => el.selected).length
        this.sellers.splice(i, 1, this.sellers[i])
      })
      // for (const i in sellers) {
      //   const products = sellers[i].products
      //   this.sellers[i].products.map(el => {
      //     return products.find(rel => rel.id == el.id) ?? el
      //   })
      //   this.sellers.splice(i, 1, this.sellers[i])
      // }
    },
    disabledCondition(item) {
      return !item.checkout_validity && item.product_variance_attribute.total_stock >= item.quantity
    },
    firebaseViewCart(data) {
      this.$firebaseLogEvent('view_cart', {
        currency: "IDR",
        items: data.map(({product, quantity}) => ({
          item_id: product.id.toString(),
          item_name: product.name,
          item_brand: product.brand_name,
          currency: "IDR",
          price: product.price_display,
          quantity: quantity,
        })),
      });
    },
    removeItem(cart) {
      axios.delete(this.apiUrl + "users/bag/takeout/" + cart.id, {
        headers: {
          api_key: this.apiKey,
          token: this.token,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        const data = this.shoppingBag;
        data.splice(
          data.findIndex((el) => el.id == cart.id),
          1
        );
        this.$store.dispatch("setShoppingBag", data);
        toaster.make(cart.product_detail.name + " has been removed from your shopping bag", "success")
        this.$firebaseLogEvent("remove_from_cart", {
          currency: "IDR",
          items: [
            {
              item_id: cart.product_detail.id.toString(),
              item_name: cart.product_detail.name,
              item_brand: cart.product_detail.brand_name,
              item_category: cart.product_detail.category3_name,
              item_variant: cart.product_detail_variance_id.toString(),
              price: cart.product_detail.price_display,
              currency: "IDR",
              quantity: cart.productQuantity,
            },
          ],
          value: cart.product_detail.price_display,
        });
        if(!this.apiUrl.includes('staging')) {
          this.$analytics.fbq.event("remove_from_cart", {
            currency: "IDR",
            items: [
              {
                item_id: cart.product_detail.id,
                item_name: cart.product_detail.name,
                item_brand: cart.product_detail.brand_name,
                item_category: cart.product_detail.category3_name,
                item_variant: cart.product_detail_variance_id.toString(),
                price: cart.product_detail.price_display,
                currency: "IDR",
                quantity: cart.productQuantity,
              },
            ],
          });
        }
      });
    },
    addQuantity(cart, merchantIndex) {
      if (cart.product_variance_attribute.total_stock > cart.quantity) {
        cart.quantity += 1
        this.sellers.splice(merchantIndex, 1, this.sellers[merchantIndex])
        axios.post(this.apiUrl + "users/bag/add", {
          product_detail_variance_id: cart.product_detail_variance_id,
          quantity: 1,
        }, { headers: {
          api_key: this.apiKey,
          token: this.token,
          "Content-Type": "application/json",
        }})
      } else toaster.make("Insufficient stock", "danger")
    },
    reduceQuantity(cart, merchantIndex) {
      const insufficientStock = cart.variant_has_stock && cart.product_variance_attribute.total_stock < cart.quantity
      if (insufficientStock || cart.quantity > 1) {
        const subtract = insufficientStock ? cart.product_variance_attribute.total_stock - cart.quantity : -1
        insufficientStock ? cart.quantity = cart.product_variance_attribute.total_stock : cart.quantity -= 1
        this.sellers.splice(merchantIndex, 1, this.sellers[merchantIndex])
        console.log(subtract);
        axios.post(this.apiUrl + "users/bag/add", {
          product_detail_variance_id: cart.product_detail_variance_id,
          quantity: subtract,
        }, { headers: {
          api_key: this.apiKey,
          token: this.token,
          "Content-Type": "application/json",
        }})
      }
    },
    totalCart() {
      return this.sellers.length ? this.sellers.map(el => el.products).reduce((a, b) => a.concat(b)).reduce((sum, product) => sum + (product.selected ? product.quantity * product.product_detail.price_display : 0), 0) : 0
    },
    async getMiniBanner(type) {
      try {
        const res = await this.$api.cms.getMiniBanner(type)
        if (res.status === 200) this.announcementBanner = res.data.data
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    getCart() {
      userHelper.setCartList();
    },
    select(event) {
      if (event.target.value === "true" || event.target.value === "false")
        this.shoppingBag.map((el) => el.selected = event.target.value === 'true' && el.checkout_validity)
      else {
        this.shoppingBag[Number(event.target.value)].selected = !this.shoppingBag[Number(event.target.value)].selected;
        this.setCheckAll();
      }
    },
    setCheckAll() {
      const countValid = this.shoppingBag.filter((el) => el.checkout_validity).length;
      const countSelected = this.shoppingBag.filter((el) => el.selected === true).length;
      this.checkedState = countValid == countSelected;
    },
    checkout() {
      // const data = JSON.parse(JSON.stringify(this.sellers)).map(seller => 
      //   seller.products.filter(product => product.selected).map(product => {
      //     product.product_detail.seller = this.shoppingBag.find(bag => bag.product.seller.id = seller.id).product.seller
      //     delete product.product_detail.seller.products
      //     return product
      // })).reduce((a, b) => a.concat(b))
      const data = JSON.parse(JSON.stringify(this.sellers)).map(seller => seller.products.filter(product => product.selected)
        .map(bag => {
          bag.product = bag.product_detail
          bag.product.seller = seller 
          delete bag.product_detail
          delete bag.product.seller.products
          return bag
        }))
        .reduce((a, b) => a.concat(b))
      if (data.length) {
        localStorage.setItem("checkoutData", JSON.stringify(data));
        this.$router.push("/checkout");
      } else toaster.make("There are no items in your bag", "danger")
    },
    // TODO Store Closed Info set text & badge color
    // Set Product Status Badge Text
    setProductBadgeText(chart) {
      //* Not Available => Out of Stock => Insufficient => Store Closed => Pre Order => Bundle [Badge Baru] (Nanti di UI UX yang baru bakal ada) => In Stock
      const processable = chart.variant_has_stock && !chart.product_variance_attribute.is_deleted && chart.product_detail.is_active && !chart.product_detail.is_deleted && chart.checkout_validity;

      if (!chart) return
      else if (!chart.variant_has_stock) return 'Out of Stock'
      else if (chart.product_variance_attribute.total_stock < chart.quantity) return 'Insufficient'
      // else if (!chart.checkout_validity) return 'Not Available'
      else if (!processable) return 'Not Available'
      else if (chart.is_closed) return 'Store Closed'
      else if (!chart.status_stock) return 'Pre-order'
      //TODO Bundle [Badge Baru] (untuk UI UX yang baru nanti)
      else return 'In Stock'
    },
    // Set Product Status Badge Bg Color
    setProductBadgeColor(chart) {
      const currentEpoch = new Date().valueOf();
      const processable = chart.variant_has_stock && !chart.product_variance_attribute.is_deleted && chart.product_detail.is_active && !chart.product_detail.is_deleted && (chart.product_detail.status_stock || !chart.product_detail.batch_po || (currentEpoch > chart.product_detail?.start_date_po && currentEpoch < chart.product_detail?.end_date_po));

      if (!chart) return
      else if (!chart.variant_has_stock) return 'outstock-badge'
      else if (chart.product_variance_attribute.total_stock < chart.quantity) return 'badge-secondary'
      // else if (!chart.checkout_validity) return 'unavailable-badge'
      else if (!processable) return 'unavailable-badge'
      else if (chart.is_closed) return 'store-closed-badge' 
      else if (!chart.status_stock) return 'preorder-badge'
      //TODO Bundle [Badge Baru] (untuk UI UX yang baru nanti)
      else return 'instock-badge'
    },
    formatPrice(value) {
      // return value ? value.toLocaleString("id") : value;
      return this.$helpers.format.price(value);
    },
    formatEpochMsToLocalTime(value) {
      return formatter.epochMsToLocalTime(value, "DD MMMM YYYY")
    },
    // Format merchant store close end date
    formatEndDateClose(value) {
      moment.locale("id"); // Set date locale to indonesia
      return value ? moment.unix(value/1000).add(1, "days").format("DD MMMM YYYY") : value;
    },
    replaceByDefaultImage(img) {
      const src = require("@/assets/img/default-image.png");
      if (img.target) img.target.src = src
      else img.medium_image_url = src
    },
    resizeSideCart() {
      const cartEle = document.querySelector("#side-cart");
      if (!cartEle) return
      // document.querySelector("#side-cart").querySelector(".modal-content").style.height = `${window.innerHeight}px`
      cartEle.querySelector(".modal-content").style.height = `${window.innerHeight}px`
    },
    setProductImgAltText(product) {
      return `${product.brand_name} ${product.name}${product.kadar > 0 ? ` with purity of ${product.kadar}` : ''}`
    },
  },
};
</script>

<style scoped>
.container-checkbox {
  font-size: 12px;
  padding-top: 3px;
}
.w-55 {
  width: 55%;
}
.hr-line-checkout {
  width: 100%;
  border-top: 1px solid #d89700;
}
</style>
