<template>
  <b-modal id="modal-adjust-quantity" lazy scrollable centered hide-header hide-footer hide-backdrop>
    <h2>Stok Tidak Memadai</h2>
    <p>Hey, ada produk yang jumlahnya melebihi stok nih. Jumlah produk ini akan disesuaikan dengan stok yang ada</p>
    <template v-for="(seller, i) in items">
      <div class="row" v-for="(item, j) in seller.products" :key="`${i}${j}`">
        <div class="col">
          <b>{{ item.product_detail.name }}</b>
          <p>
            <template v-if="item.product_variance_attribute.color.name[0] != '-'">
              Color: {{ item.product_variance_attribute.color.name }}
            </template>
            <template v-if="item.product_variance_attribute.size.international[0] != '-'">
              | Size: {{ item.product_variance_attribute.size.international }}
            </template>
          </p>
        </div>
        <div class="col-auto">
          <b>Jumlah</b>
          <p>{{ item.quantity }} -> {{ item.product_variance_attribute.total_stock }}</p>
        </div>
      </div>
    </template>
    <p>Apakah kamu mau menyesuaikan jumlahnya?</p>
    <div class="text-right">
      <button class="btn btn-wz-outline-gold ml-2" @click="$bvModal.hide('modal-adjust-quantity')">Tidak</button>
      <button class="btn btn-wz-gold ml-2" @click="$emit('adjustQuantity')">Ya</button>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'AdjustQuantity',
  props: ['items'],
}
</script>
